function activenav() {
  // Get the current path of the URL
  var path = window.location.pathname;

  var currentPathname = (path || "").replace(/\/$/, "").replace(/\.html$/, "");
  // Select the navbar and its links
  var navLinks = document.querySelector(".navbar");
  var navLink = navLinks.querySelectorAll("a");

  // Iterate over each link
  navLink.forEach(function (link) {
    // Get the href attribute of the link
    var href = link.getAttribute("href");
    if (href) {
      var linkUrl = new URL(href, window.location.origin);
      var linkPathname = linkUrl.pathname
        .replace(/\/$/, "")
        .replace(/\.html$/, "");

      // Check if the href matches the current path
      if (linkPathname === currentPathname) {
        // Add 'active' class to the matching link
        link.classList.add("active");

        // Check if the link is within a dropdown menu
        var dropdown = link.closest(".dropdown-menu");
        if (dropdown) {
          // Find the dropdown toggle button
          var dropdownToggle = dropdown.previousElementSibling;
          if (dropdownToggle) {
            // Add 'active' class to the dropdown toggle button
            dropdownToggle.classList.add("active");

            // Check if the dropdown toggle button is within another dropdown
            var nesteddropdown = dropdownToggle.closest(".dropdown-menu");
            if (nesteddropdown) {
              // Find the nested dropdown toggle button
              var nesteddropdownToggle = nesteddropdown.previousElementSibling;
              if (nesteddropdownToggle) {
                // Add 'active' class to the nested dropdown toggle button
                nesteddropdownToggle.classList.add("active");
              }
            }
          }
        }
      }
    }
  });
}

// Run the function after the DOM has loaded
document.addEventListener("DOMContentLoaded", activenav);
