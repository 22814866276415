document.addEventListener("scroll", function() {
    const tiles = document.querySelectorAll(".tile");

    tiles.forEach((tile, index) => {
        const rect = tile.getBoundingClientRect();
        const nextTile = tiles[index + 1];
        if(rect.top <= 0 && rect.bottom > 0){
            tile.classList.add("sticky");

            tile.style.zIndex = 10 + index;
            if(nextTile) nextTile.style.zIndex = 10 + index + 1;

            for(let i = 0; i < index; i++) {
                tiles[i].classList.remove("sticky");
            }
        } else {
            tile.classList.remove("sticky");
        }
    })

})
