import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

document.addEventListener("DOMContentLoaded", () => {
    const casestudySlider = document.querySelector(".case-study-slider");
    var swiper = new Swiper(".case-study-slider", {
        modules: [Navigation, Pagination, Autoplay],
        // slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        allowTouchMove: true,
        breakpoints: {
          200: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1020: {
            slidesPerView: 4,
          },
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        autoplay: {
            delay: 5000,
            pauseOnMouseEnter: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    const prevBtn = document.querySelector(
      ".case-study-slider .swiper-button-prev"
    );
    const nxtBtn = document.querySelector(
      ".case-study-slider .swiper-button-next"
    );

    if (prevBtn && nxtBtn) {
      const handleScreenSizeChange = () => {
        if (window.innerWidth > 768) {
          prevBtn.classList.add("swiper-button__show");
          nxtBtn.classList.add("swiper-button__show");
        } else {
          prevBtn.classList.remove("swiper-button__show");
          nxtBtn.classList.remove("swiper-button__show");
        }
      };

      handleScreenSizeChange();

      casestudySlider.addEventListener("mouseout", () => {
          prevBtn.classList.remove("swiper-button__show");
          nxtBtn.classList.remove("swiper-button__show");
      });

      casestudySlider.addEventListener("mouseover", () => {
        if (window.innerWidth > 768) {
        prevBtn.classList.add("swiper-button__show");
        nxtBtn.classList.add("swiper-button__show");
        }
      });

      window.addEventListener("resize", handleScreenSizeChange);
    }
});

